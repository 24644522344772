@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-gray-800;
  @apply font-opensans;
}

@font-face {
  font-family: "opensans";
  src: url("/public/Open-Sans.ttf");
}
@font-face {
  font-family: "montserrat-bold";
  src: url("/public/Montserrat-Bold.ttf");
}
